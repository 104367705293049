import { IActionContext } from '@msdyn365-commerce/core';
import { FeatureState } from '@msdyn365-commerce/retail-proxy';
import { addCartLinesAsync, addCouponsAsync, createCartAsync, readAsync, removeCartLinesAsync, searchAsync, updateAsync } from '@msdyn365-commerce/retail-proxy/dist/DataActions/CartsDataActions.g';
import { getChannelDeliveryOptionConfigurationAsync,getCustomerLoyaltyCardsAsync,getFeatureStatesAsync } from '@msdyn365-commerce/retail-proxy/dist/DataActions/StoreOperationsDataActions.g';
import { Cart, CartLine, CartSearchCriteria, Coupon, QueryResultSettings } from '@msdyn365-commerce/retail-proxy/dist/Entities/CommerceTypes.g';

const enum CartTokenPrefix {
    Auth = 't',
    Anon = 'p'
}

export default async function getOrCreateActiveCart(ctx: IActionContext): Promise<Cart | undefined> {
    let cart: Cart | undefined;
    const cartCookie: string = ctx.requestContext.cookies.getCartCookie();
    const cartCookieParts: string[] = cartCookie.split(':');
    try {
        // Check if there are already is a cart cookie and it is in the format we understand
        cart = await getCartFromCookie(cartCookieParts, ctx);
        const wasReadFromCookie = !!cart && cart.Id;

        // If the customer is authenticated, and the auth cookie didn't give us a cart OR doesn't have a cookie, search for the customer's cart
        if (!(cart && cart.Id)) {
            cart = await getCartFromCustomer(ctx);
        }

        // if the customer just went from anon to signed in state then get that anon cart
        // if the search of carts didn't result in a cart, claim the current anon cart as a new auth cart for the customer
        // if the search resulted in a cart, and the read also resulted in cart, then go ahead an merge the carts
        const anonCart = await claimAnonCart(cartCookieParts, cart, ctx);
        if (anonCart) {
            cart = anonCart;
        }

        // If there is no cookie or everything else fails, create an empty cart and return that for the customer
        if (!(cart && cart.Id)) {
            cart = await createNewCart(ctx);
        }
        if (!wasReadFromCookie && ctx.requestContext.user.isAuthenticated && cart && !cart.LoyaltyCardId) {
            const card = await getCustomerLoyaltyCardsAsync({ callerContext: ctx, queryResultSettings: {} }, null);
            if (card && card.length > 0) {

                cart = await updateAsync(
                    { callerContext: ctx },
                    {
                        Id: cart.Id,
                        LoyaltyCardId: card[0].CardNumber
                    }
                );
            }
        }

    } catch (ex) {
        ctx.telemetry.error(`Caught exception in getting cart: ${ex}`);
        cart = await createNewCart(ctx);
    }
    return cart;
}

export async function createNewCart(ctx: IActionContext): Promise<Cart | undefined> {
    const newCart = await createCartAsync({ callerContext: ctx }, <Cart>{});
    if (newCart && newCart.Id) {
        ctx.requestContext.cookies.setCartCookie(newCart, ctx.requestContext.user.isAuthenticated);

        return newCart;
    }

    return undefined;
}

export async function getCartFromCookie(cartCookieParts: string[], ctx: IActionContext): Promise<Cart | undefined> {
    if (cartCookieParts && cartCookieParts.length === 2) {
        // If the customer is authenticated and they have an auth cookie
        // OR if the customer is not authenticated and then have an anon cookie
        // just get the cart and return that

        if ((ctx.requestContext.user.isAuthenticated && cartCookieParts[0] === CartTokenPrefix.Auth) ||
            (!ctx.requestContext.user.isAuthenticated && cartCookieParts[0] === CartTokenPrefix.Anon)) {
            const readCart = await readAsync({ callerContext: ctx, bypassCache: 'none' }, cartCookieParts[1]);

            if (readCart && readCart.Id) {
                ctx.requestContext.cookies.setCartCookie(readCart, ctx.requestContext.user.isAuthenticated);
                return readCart;
            }
        }
    }

    return undefined;
}

export async function getCartFromCustomer(ctx: IActionContext): Promise<Cart | undefined> {
    if (ctx.requestContext.user.isAuthenticated) {
        const authCarts = await searchCarts(ctx);
        if (authCarts && authCarts.length > 0) {
            ctx.requestContext.cookies.setCartCookie(authCarts[0], ctx.requestContext.user.isAuthenticated);
            return authCarts[0];
        }
    }

    return undefined;
}

export async function claimAnonCart(cartCookieParts: string[], currentCart: Cart | undefined, ctx: IActionContext): Promise<Cart | undefined> {
    if (ctx.requestContext.user.isAuthenticated && cartCookieParts && cartCookieParts.length === 2 && cartCookieParts[0] === CartTokenPrefix.Anon) {
        const anonCurrentCart = await readAsync({ callerContext: ctx, bypassCache: 'none' }, cartCookieParts[1]);

        if (anonCurrentCart && !currentCart) {
            const newCart = await claimCart(anonCurrentCart.Id, ctx);
            if (newCart && newCart.Id) {
                ctx.requestContext.cookies.setCartCookie(newCart, ctx.requestContext.user.isAuthenticated);
                return newCart;
            }
        }

        if (anonCurrentCart && anonCurrentCart.Id && currentCart) {
            const mergedCart = await addCartLines(anonCurrentCart, currentCart, ctx);
            // Once updated, take that merged cart ID and store the cart ID in the cookie
            if (!(mergedCart instanceof Error) && mergedCart.Id) {
                ctx.requestContext.cookies.setCartCookie(mergedCart, ctx.requestContext.user.isAuthenticated);
                return mergedCart;
            }
        }
    }

    return undefined;
}

/**
 * Function that copies a cartline for giftcard
 * @param cartline Source CartLine you want to copy
 */
function buildGiftCardLine(cartline: CartLine): CartLine {
    return {
        DeliveryMode: cartline.DeliveryMode,
        CatalogId: cartline.CatalogId,
        Description: cartline.Description,
        EntryMethodTypeValue: cartline.EntryMethodTypeValue || 3,
        ItemId: cartline.ItemId,
        ProductId: cartline.ProductId,
        Quantity: cartline.Quantity,
        Price: cartline.Price,
        NetPrice: cartline.NetPrice,
        GiftCardBalance: cartline.GiftCardBalance,
        TrackingId: '',
        UnitOfMeasureSymbol: cartline.UnitOfMeasureSymbol,
        IsPriceKeyedIn: true,
        IsGiftCardLine: true,
        ExtensionProperties: cartline.ExtensionProperties
    };
}

/**
 * Function that adds cart lines from source cart to destination cart
 * @param sourceCart Source cart you want to add cart lines from
 * @param destinationCart Destination cart that you want to add cart lines to
 * @param ctx Request context
 */
// tslint:disable-next-line:cyclomatic-complexity
export async function addCartLines(sourceCart: Cart, destinationCart: Cart, ctx: IActionContext): Promise<Cart> {
    let updatedCart;
    const cartLines: CartLine[] = [];
    const cartLinesToDelete: string[] = [];
    let hasShippingMethod:boolean;
    const channelConfiguration = ctx.requestContext.channel;
    const featureNames: string[] = [
        'Dynamics.AX.Application.RetailB2BEcommerceFeature',
        'Dynamics.AX.Application.RetailDefaultOrderQuantityLimitsFeature',
        'Dynamics.AX.Application.RetailMultiplePickupDeliveryModeFeature'
    ];
    if (destinationCart.Version) {
        if (sourceCart.CartLines && sourceCart.CartLines.length > 0) {
            for (const cartline of sourceCart.CartLines) {
                let newCartLine: CartLine;

                if (cartline.IsGiftCardLine) {
                    newCartLine = buildGiftCardLine(cartline);
                } else {
                    newCartLine = {};
                    const featureStates = await getFeatureStatesAsync({ callerContext: ctx }, featureNames);
                    const isfeatureStatesEnabled = featureStates?.
                         find((featureState:FeatureState) => featureState.Name === 'Dynamics.AX.Application.RetailMultiplePickupDeliveryModeFeature')?.IsEnabled || false;
                    if(isfeatureStatesEnabled) {
                        const channelDeliveryOptionConfig = await getChannelDeliveryOptionConfigurationAsync({ callerContext: ctx });
                        hasShippingMethod = cartline.DeliveryMode === channelDeliveryOptionConfig.PickupDeliveryModeCodes?.find((deliveryMode:string) => deliveryMode === cartline.DeliveryMode);

                        if (cartline.DeliveryMode !== undefined && hasShippingMethod) {
                            newCartLine.DeliveryMode = cartline.DeliveryMode;
                            newCartLine.FulfillmentStoreId = cartline.FulfillmentStoreId;
                            newCartLine.ShippingAddress = cartline.ShippingAddress;
                        }
                    } else {
                        if(cartline.DeliveryMode && cartline.DeliveryMode !== '' && channelConfiguration && cartline.DeliveryMode === channelConfiguration?.PickupDeliveryModeCode) {
                            newCartLine.DeliveryMode = channelConfiguration.PickupDeliveryModeCode;
                            newCartLine.FulfillmentStoreId = cartline.FulfillmentStoreId;
                            newCartLine.ShippingAddress = cartline.ShippingAddress;
                        }
                    }
                    newCartLine.ProductId = cartline.ProductId;
                    newCartLine.Quantity = cartline.Quantity;
                    newCartLine.ExtensionProperties = cartline.ExtensionProperties;
                    newCartLine.Description = cartline.Description;
                    newCartLine.ReasonCodeLines = cartline.ReasonCodeLines;
                }

                cartLines.push({ ...newCartLine });
                if (cartline.LineId) {
                    cartLinesToDelete.push(cartline.LineId);
                }
            }
        }

        if (cartLines.length > 0) {
            try {
                updatedCart = await addCartLinesAsync({ callerContext: ctx }, destinationCart.Id, cartLines, destinationCart.Version);

                if (cartLinesToDelete.length > 0) {
                    sourceCart = await removeCartLinesAsync({ callerContext: ctx }, sourceCart.Id, cartLinesToDelete);
                }
            } catch (e) {
                ctx.telemetry.error('Error adding cart lines to desination cart');
                ctx.telemetry.exception(e);
                return destinationCart;
            }
        }

        // Copy over coupon codes from source cart to destination cart so that the customer doesn't lose couponCodes while migration
        if (sourceCart.Coupons && sourceCart.Coupons.length > 0) {
            const coupons = sourceCart.Coupons.map((coupon: Coupon) => {
                return coupon.Code!;
            });

            try {
                updatedCart = await addCouponsAsync({ callerContext: ctx }, destinationCart.Id, coupons, false);
            } catch (e) {
                ctx.telemetry.error('Error adding existing coupon codes to the cart');
                ctx.telemetry.exception(e);
                return destinationCart;
            }
        }
    }

    return updatedCart || destinationCart;
}

/**
 * Function to claim a cart as auth cart and set the browser cookie accordingly
 *
 * @param cartId Cart Id to claim as auth cart
 * @param ctx Request Context
 */
export async function claimCart(cartId: string, ctx: IActionContext): Promise<Cart> {
    const claimAuthCart = await updateAsync(
        { callerContext: ctx },
        {
            Id: cartId
        }
    );
    if (!(claimAuthCart instanceof Error) && claimAuthCart && claimAuthCart.Id) {
        return claimAuthCart;
    } else {
        return <Cart>{};
    }
}

/**
 * Function to search carts that belong to a customer
 *
 * @param ctx Request context
 */
export async function searchCarts(ctx: IActionContext): Promise<Cart[]> {
    const cartSearchCriteria: CartSearchCriteria = {
        IncludeAnonymous: false,
        CartTypeValue: 1
    };

    const queryResultSettings: QueryResultSettings = {
        Paging: {
            Top: 1,
            Skip: 0
        },
        Sorting: {
            Columns: [
                {
                    ColumnName: 'ModifiedDateTime'
                }
            ]
        }
    };

    return searchAsync({ callerContext: ctx, queryResultSettings: queryResultSettings }, cartSearchCriteria);
}